import React, { useState, useEffect, useContext, useRef } from 'react';
import { 
  Row, 
  Col, 
  Modal, 
  Button, 
  Container, 
  Form, 
  Spinner,
  Card,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Table
} from 'react-bootstrap';


import {
  closeBtn,
  informationIcon,
} from './../../assets/images';


import {
  variables,
  helpers,
  types,
  authContextHelper,
  api,
} from 'utilities';

import { MultiSelect } from 'react-multi-select-component';
import { BiCopy } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSms } from '@fortawesome/pro-regular-svg-icons';



/**
 * User Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
export const UserDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} className="k-modal k-m-right" >
        <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
          <Row className='mt-1 k-panel-cent-vert'>
            <Col className='col-10'>
              <Modal.Title as="h5" className='p-1'>Confirm User Deletion</Modal.Title>
            </Col>
            <Col className='col-2 k-center-content-hor-right'>
              <button className='custom-close-button k-pointer' onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className='pt-2 pb-2'>
          <Row className='mt-2 m-2'>
            <Col className='col-9 p-0'>
              <span>Do you really want to delete <span className='txt-bold'>{name || 'this user'}</span>? This process cannot be undone.</span>
            </Col>
            <Col className='k-center-content p-0'>
              <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

/**
 * Group Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
export const GroupDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} centered contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} className="k-modal k-m-right" >
        <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
          <Row className='mt-1 k-panel-cent-vert'>
            <Col className='col-10'>
              <Modal.Title as="h5" className='p-1'>Confirm Group Deletion</Modal.Title>
            </Col>
            <Col className='col-2 k-center-content-hor-right'>
              <button className='custom-close-button k-pointer' onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className='pt-2 pb-2'>
          <Row className='mt-2 m-2'>
            <Col className='col-9 p-0'>
              <span>Do you really want to delete <span className='txt-bold'>{name || 'this group'}</span>? This process cannot be undone.</span>
            </Col>
            <Col className='k-center-content p-0'>
              <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

/**
 * User Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
export const UserAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setNameFieldsValid(true);
    setDisplayNameValid(true);
    setEmailValid(false);
    setPasswordValid(false);
    setPhoneValid(true);
    setRoleValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
  
    if (variables.DEFAULT_FORM_NAME_FIELDS.includes(name)) {
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(value) || value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
  
    if (name === 'email') {
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
  
    if (name === 'displayName') {
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(value) || value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }
  
    if (name === 'phone') {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(value) || value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }
  
    if (name === 'password') {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(value) || value === '') {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
      if (value === modalFormBody.password) {
        setConfirmPasswordValid(true);
      } else {
        setConfirmPasswordValid(false);
      }
    }
  
    if (name === 'confirmPassword') {
      if (value === modalFormBody.password) {
        setConfirmPasswordValid(true);
      } else {
        setConfirmPasswordValid(false);
      }
    }
  
    if (name === 'role') {
      if (value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }
  
    setModalFormBody({
      ...modalFormBody,
      [name]: value,
    });
  
    if (isDisplayNameValid && isEmailValid && isRoleValid && isPasswordValid && confirmPasswordValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };
  

  const validateForm = () => {
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();
    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid && isPasswordValid){
      setSubmitClicked(true);

      await handleModalFormSubmit({body: {
        // ...modalFormBody,
        name: {
          last: modalFormBody['lastName'] || '',
          first: modalFormBody['firstName'] || '',
          middle: modalFormBody['middleName'] || '',
        },
        display_name: modalFormBody['displayName'] || '',
        email: modalFormBody['email']?.toLowerCase() || '',
        phone: modalFormBody['phone'] || '',
        profile_photo: {
          avatar: 'default'
        },
        role: modalFormBody['role'] || '',
        pword: modalFormBody['password'] || '',
      }});

      setSubmitClicked(false);

    } else {
      helpers.logToOutput('FORM NOT VALID... Won\'t make it so 7');
    }
  }

  const userobj = {
    'name': {
      'last': 'wick',
      'first': 'john',
      'middle': ''
    },
    'display_name': 'jwick',
    'email': 'me@kuro.black',
    'phone': '',
    'profile_photo': {
      'avatar': 'default'
    },
    'role': 'user'
  }

  const renderPasswordTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props} className='k-z-ind-999996'>
      Simple tooltip
    </Tooltip>
  );

  const renderPasswordPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" style={{ backgroundColor: '#f8f9fa', fontWeight: 'bold', fontSize: '1.1rem' }}>
        Password Help
      </Popover.Header>
      <Popover.Body style={{ padding: '0.5rem', fontSize: '0.9rem' }}>
        <p style={{ marginBottom: '0.2rem' }}>Passwords must be at least <strong>12 characters</strong> and contain:</p>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem', lineHeight: '1.5' }}>
          <li>1 <span style={{ color: '#dc3545' }}>uppercase character</span></li>
          <li>1 <span style={{ color: '#dc3545' }}>symbol</span> (e.g.. <span style={{ color: '#dc3545' }}>!, ?</span>)</li>
          <li>1 <span style={{ color: '#dc3545' }}>number</span></li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} className="k-modal k-m-right" >
        <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
          <Row className='mt-1 k-panel-cent-vert'>
            <Col className='col-10'>
              <Modal.Title as="h5" className='p-1'>Add User</Modal.Title>
            </Col>
            <Col className='col-2 k-center-content-hor-right'>
              <button className='custom-close-button k-pointer' onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        
        <Modal.Body className="k-modalBody">
          <Form onSubmit={handleFormSubmit}>
            <Container className="pe-0">
              <Row className="m-0 mb-3">
                <Col className="col-12 ps-0">
                  <span className='txt-10 color-lightHeader'>eMail</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.Email">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="email" placeholder="Email" name='email'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                    {isEmailValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid Email</small>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0 mb-3">
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Full Name</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="First Name" name='firstName'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Display Name</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.DisplayName">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Display Name" name='displayName'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0 mb-3">
                <Col className="col-6 ps-0">
                  <div>
                    <span className='txt-10 color-lightHeader'>Password</span>
                    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderPasswordPopover}>
                      <img className='ms-1 k-mh-12 k-opac-50pc k-pointer' src={informationIcon}></img>
                    </OverlayTrigger>
                  </div>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.Password">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="password" placeholder="Password" name='password'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                    {isPasswordValid ?  null: <small className='ps-0' style={{color: 'red'}}>Password not valid </small>}
                  </Form.Group>
                </Col>
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Confim Password</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.ConfirmPassword">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="password" placeholder="Confirm Password" name='confirmPassword'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                    {confirmPasswordValid ? null : <small className='ps-0' style={{ color: 'red' }}>Passwords do not match</small>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className='m-0'>
                {isNameFieldsValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(1 - 25 Alphanumeric only)'}</small>}
                {isDisplayNameValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid Display Name. {'(1 - 25 Alphanumeric only)'}</small>}
              </Row>

              <Row className="m-0">
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Role</span>
                  <Form.Group className="p-0 k-bord-col-trans k-outline-none" controlId="modalUserAddForm.Role">
                    <Form.Select aria-label="Select a role" className="k-inputSelect k-inputText15-light k-bord-col-trans k-outline-none ps-0" name='role' defaultValue="" onChange={handleInputChange} >
                      <option disabled value="" >Select Role</option>
                      <option value="user" >User</option>
                      <option value="administrator">Admin</option>
                    </Form.Select>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
                <Col className="col-6 p-0 k-center-content-hor-right">
                  <div className="pt-3 k-center-content-hor-right-end">
                    <Button variant="secondary " 
                      type="submit"
                      disabled={!isDisplayNameValid || !isRoleValid || !isEmailValid || !isPasswordValid || submitClicked}
                      size="sm" className="k-btn-submit-col txt-14 k-center-content-hor-right-cen k-h-30">
                      Make it so
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className='m-0'>
                {isRoleValid ?  null: <small className='ps-0'  style={{color: 'red'}}>Select User's Role</small>}
                {submitError? (<>
                  <small style={{color: 'red'}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}

/**
 * Group Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
export const GroupAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(false);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  const [submitClicked, setSubmitClicked] = useState(false);


  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError, users } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setGroupNameValid(false);
    setGroupDescriptionValid(true);
    setGroupUsers([]);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === 'name') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    }

    if(event.target.name === 'description') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === '') {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    }


    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }

  const addGroupUser = (event: any, userId: any) => {
    event.preventDefault();

    setGroupUsers([
      ...groupUsers,
      userId
    ]);

  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isGroupNameValid && isGroupDescriptionValid){
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody,
        users: [
          ...groupUsers
        ]
      }});
      handleClose();
      setSubmitClicked(false);

    } else {
      helpers.logToOutput('FORM NOT VALID... Won\'t make it so 8');
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} className="k-modal k-m-right" >
        <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
          <Row className='mt-1 k-panel-cent-vert'>
            <Col className='col-10'>
              <Modal.Title as="h5" className='p-1'>Add Group</Modal.Title>
            </Col>
            <Col className='col-2 k-center-content-hor-right'>
              <button className='custom-close-button k-pointer' onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="k-modalBody">
          <Form onSubmit={handleFormSubmit}>
            <Container className="pe-0">
              <Row className="m-0 mb-3">
                <Col className="col-12 ps-0">
                  <span className='txt-10 color-lightHeader'>Group Name</span>
                  <Form.Group className="p-0 " controlId="modalGroupAddForm.Name">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Group Name" name='name'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0 mb-3">
                <Col className="col-12 ps-0">
                  <span className='txt-10 color-lightHeader'>Group Description</span>
                  <Form.Group className="p-0 " controlId="modalGroupAddForm.Description">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Group Description" name='description'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="col-6 p-0 k-center-content-hor-right">
                  <div className="pt-3 k-center-content-hor-right-end">
                    <Button variant="secondary " 
                      type="submit"
                      disabled={!isGroupNameValid || !isGroupDescriptionValid || submitClicked}
                      size="sm" className="k-btn-submit-col txt-14 k-center-content-hor-right-cen k-h-30">
                      Make it so
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                {isGroupNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Group Name. {'(5 - 25 Alphanumeric only)'}</small>}
                {isGroupDescriptionValid ?  null : <small style={{color: 'red'}}>Enter a valid Group Description. {'(Alphabets, Numbers, space and charcaters[-., ()/] accepted)'}</small>}
                {submitError? 
                  (<>
                    <small style={{color: 'red'}}>{submitError}</small>
                  </>): null
                }
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export const GenericPromptModal: React.FC<types.IGenericPromptModal> = ({ content, isVisible, handleModalPromptConfirm, handleModalHide }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if(isVisible){
      // Simulate an async operation unitil we put proper feedback in from API
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); // Adjust the delay as needed for your use case
    }
  }, [isVisible])


  return (
    <Modal show={isVisible} onHide={handleModalHide} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{content.title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={handleModalHide} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className='col-12'>
            <Row className='pt-2'>
              <Col className='k-center-content'>
                {isLoading ? (
                  <div className='k-mh-25 k-center-content-hor-right k-center-content'>
                    <Spinner animation="border" role="status" >
                      <span className="visually-hidden">{content.loadingMessage}</span>
                    </Spinner>
                  </div>
                ) : (
                  <div  className='k-center-content-hor-right k-center-content'>
                    <span className="visually-hidden">{content.loadedMessage}</span>
                  </div>
                )}
              </Col>
            </Row>
            {/*<Row className='pt-2'><Col className='col-12'><span className='txt-14 pt-2'>You can now close this window</span></Col></Row>*/}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalSharelink: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodStatus }) => {
  const [shareLinkStatus, setShareLinkStatus] = useState<boolean>(false);
  const [shareLink, setShareShareLink] = useState<string>('');
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const getShareLink = () => {
    setShareLinkStatus(false);

    const query = {
      id: prodId,
      body : {
        email: '',
        duration: 60,
      }
    }
    
    api.presignProductLink(query)
      .then(result => {
        setShareShareLink(result.url);
        setShareLinkStatus(true);
      })
      .catch(error => {
        setShareShareLink('Oh ... something went wrong!');
        setShareLinkStatus(true);
      });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setLinkCopied(true);    
  };

  //Run on load
  useEffect(() => {
    if (status == true){
      setLinkCopied(false);
      getShareLink();

    }
  }, [status]);

  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Generate your personalised link below to easily share with others. Simply copy and send it to allow them direct access to your virtual machine!</span>
        </Row>
        <Row>
          <p className="mb-0 mt-4">
            { prodStatus === 'running' ?
              <>
                {!shareLinkStatus ? 
                  <Spinner animation="border" variant="secondary" />
                  : 
                  <Row>
                    <Col className='col-10'>
                      <span className='k-clip-text txt-12 pt-2 color-RS'>{shareLink}</span>
                    </Col>
                    <Col className='col-2'>
                      <button className='k-click' onClick={copyToClipboard} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                        <BiCopy />
                      </button>
                    </Col>
                  </Row>
                }
              </>
              :
              <>
                <Row>
                  <Col className='col-12'>
                    <span className='k-clip-text txt-12 pt-1 color-RS'>Sorry! Your desktop needs to be running to generate a share link!</span>
                  </Col>
                </Row>
              </>
            }
            {
              /*linkCopied ? 
                  <Row><Col><span className='color-lightText'>copied!</span></Col></Row> 
                  : 
                  <Row><Col><span className='color-transparent'>_</span></Col></Row> 
                */
            }
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalMessages: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodStatus, prodName }) => {
  const [messagesStatus, setMessagesStatus] = useState<boolean>(false);
  const [messages, setMessages] = useState<types.ProductMessage[] | null>([]);
  const [selectedMessage, setSelectedMessage] = useState<types.ProductMessage | null>(null);

  const getMessages = async (productId: string) => {
    setMessagesStatus(false);
    const query = {
      id: productId,
      body: {
        key: '',
      },
    };
  
    api.getProductMessages(query)
      .then((result) => {
        try {
          const responseObj = typeof result.body.responseObj === 'string'
            ? JSON.parse(result.body.responseObj)
            : result.body.responseObj;
  
          helpers.logToOutput('getMessages Modal MATCH (Parsed):', responseObj);
  
          const sortedMessages = (responseObj.items || []).sort(
            (a: types.ProductMessage, b: types.ProductMessage) => {
              const dateA = new Date(a.received_at || 0).getTime();
              const dateB = new Date(b.received_at || 0).getTime();
              return dateB - dateA;
            }
          );
  
          setMessages(sortedMessages); // Set the sorted array of messages
          setSelectedMessage(sortedMessages[0] || null); // Default to the first (newest) message
          setMessagesStatus(true);
        } catch (error) {
          helpers.logToOutput('JSON Parsing Error:', error);
          setMessagesStatus(true);
        }
      })
      .catch((error) => {
        helpers.logToOutput('getMessages Error:', error);
        setMessagesStatus(true);
      });
  };

  useEffect(() => {
    helpers.logToOutput('CenterModalMessages Status:', status);
    if (status === true) {
      getMessages(prodId);
    }
  }, [status]);

  return (
    <Modal show={status} onHide={() => setShow(false)} centered dialogClassName='k-message-modal' className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title} for {prodName}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className='m-0 ps-2 ps-0'>
          <Col className='col-4 pt-4 ps-0'>
            {messagesStatus ? (
              messages && messages.length > 0 ? (
                <>
                  <Table className='k-brd-transparent'>
                    <div className="k-overflow-y-scroll" style={{ height: '400px' }}>
                      <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                        {messages.map((message) => (
                          <tr className='k-bord-col-trans' key={`prod-messages-${message?.message_id}`}>
                            <td className='pt-1 pb-1' onClick={() => setSelectedMessage(message)}>
                              <div key={message.message_id} className={selectedMessage?.message_id === message.message_id ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5 k-wh-100pc'}>
                                <Row>
                                  <Col className='col-2 pe-0'>
                                    <FontAwesomeIcon size="xl" icon={faSms} fixedWidth />
                                  </Col>
                                  <Col className='col-10'>
                                    <Row><Col className='col-12'>{message.sender ?? 'None'}</Col></Row>
                                    <Row>
                                      <Col className="col-12 txt-12 color-lightText">
                                        {new Date(message.received_at).toLocaleString('en-GB', { timeZone: 'UTC' })}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </Table>
                </>
              ) : (
                <p>No messages available.</p>
              )
            ) : (
              <p>Loading messages...</p>
            )}
          </Col>
          <Col className="col-8 p-0">
            <Row className="m-0 mt-2">
              <Col className="col-12 pt-3">
                {selectedMessage ? (
                  <>
                    <div className="k-overflow-y-scroll" style={{ height: '400px' }}>
                      <div className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                        <Card className='k-b-shadow-none ps-0 growable-div k-overflow-a pt-1 mb-0'>
                          <Card.Header className='ps-3'>
                            <Row>
                              <Col className='col-8'>
                                <Row>
                                  <Col className='col-12'>
                                    <h4 className='mt-0 mb-0'>{selectedMessage.sender}</h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className='col-12 txt-12 color-lightText'>
                                    Sent: {new Date(selectedMessage.received_at).toLocaleString('en-GB', { timeZone: 'UTC' })}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body className='p-0 pt-4'>
                            <Row>
                              <Col md={12}>
                                <Card>
                                  <Card.Header className='p-2 ps-3 txt-14' ><strong>Message Details</strong></Card.Header>
                                  <ListGroup variant="flush txt-12">
                                    <ListGroup.Item>
                                      <Row>
                                        <Col className='col-3'><strong>Sender:</strong></Col>
                                        <Col className='col-9'>{(selectedMessage.sender ?? 'None')}</Col>
                                      </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col className='col-3'><strong>Recipient:</strong> </Col>
                                        <Col className='col-9'>{selectedMessage.destination ?? 'None'} </Col>
                                      </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col className='col-3'><strong>Received At</strong></Col>
                                        <Col className='col-9'>{new Date(selectedMessage.received_at).toLocaleString('en-GB', { timeZone: 'UTC' })}</Col>
                                      </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col className='col-3'><strong>Delivered:</strong> </Col>
                                        <Col className='col-9'>{selectedMessage.delivered ? 'Yes' : 'No'}</Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col md={12}>
                                <Card>
                                  <Card.Header className='p-2 ps-3 txt-14'><strong>Message Content</strong></Card.Header>
                                  <Row className='ps-3'>
                                    <Col className='p-2 ps-3'>
                                      {selectedMessage.clean_text}
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Select a message to view details</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalSetSecurityGroup: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodStatus }) => {
  const [shareLinkStatus, setShareLinkStatus] = useState<boolean>(false);
  const [shareLink, setShareShareLink] = useState<string>('');
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const getShareLink = () => {
    setShareLinkStatus(false);

    const query = {
      id: prodId,
      body : {
        email: '',
        duration: 60,
      }
    }
    
    api.presignProductLink(query)
      .then(result => {
        setShareShareLink(result.url);
        setShareLinkStatus(true);
      })
      .catch(error => {
        setShareShareLink('Oh ... something went wrong!');
        setShareLinkStatus(true);
      });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setLinkCopied(true);    
  };

  //Run on load
  useEffect(() => {
    if (status == true){
      setLinkCopied(false);
      getShareLink();

    }
  }, [status]);

  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Show passwords</span>
        </Row>
        <Row>
          <p className="mb-0 mt-4">
            { prodStatus === 'running' ?
              <>
                {!shareLinkStatus ? 
                  <Spinner animation="border" variant="secondary" />
                  : 
                  <Row>
                    <Col className='col-10'>
                      <span className='k-clip-text txt-12 pt-2 color-RS'>{shareLink}</span>
                    </Col>
                    <Col className='col-2'>
                      <button className='k-click' onClick={copyToClipboard} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                        <BiCopy />
                      </button>
                    </Col>
                  </Row>
                }
              </>
              :
              <>
                <Row>
                  <Col className='col-12'>
                    <span className='k-clip-text txt-12 pt-1 color-RS'>Sorry! Your desktop needs to be running to generate a share link!</span>
                  </Col>
                </Row>
              </>
            }
            {
              /*linkCopied ? 
                  <Row><Col><span className='color-lightText'>copied!</span></Col></Row> 
                  : 
                  <Row><Col><span className='color-transparent'>_</span></Col></Row> 
                */
            }
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalShowPassword: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodStatus }) => {
  const [credentialStatus, setCredentialsStatus] = useState<boolean>(false);
  const [credentialAdmin, setCredentialAdmin] = useState<string>('');
  const [credentialStandard, setCredentialUser] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [credsCopied, setCredsCopied] = useState<boolean>(false);
  const [isAdminPasswordVisible, setIsAdminPasswordVisible] = useState(false);
  const [isStandardPasswordVisible, setIsStandardPasswordVisible] = useState(false);
  const [isAdminHovered, setIsAdminHovered] = useState(false);
  const [isStandardHovered, setIsStandardHovered] = useState(false);

  const [showAdmin, setShowClickedAdmin] = useState(false);
  const [showStandard, setShowClickedStandard] = useState(false);
  const targetAdmin = useRef(null);
  const targetStandard = useRef(null);

  const toggleAdminPasswordVisibility = () => {
    setIsAdminPasswordVisible(!isAdminPasswordVisible);
  };

  const toggleStandardPasswordVisibility = () => {
    setIsStandardPasswordVisible(!isStandardPasswordVisible);
  };

  const getCredentials = () => {
    setCredentialsStatus(false);

    const query = {
      id: prodId,
      body: {
        key: ''
      }
    };

    api.getProductCredentials(query)
      .then(result => {
        setPasswordMessage('Passwords retrieved');
        setCredentialAdmin(result.body.responseObj.admin);
        setCredentialUser(result.body.responseObj.standard);
        setCredentialsStatus(true);
      })
      .catch(error => {
        setPasswordMessage('Oh ... something went wrong!');
        setCredentialsStatus(true);
      });
  };

  const hideTooltipAfterTimeout = (setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>) => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 750);
  };

  const copyStandardToClipboard = () => {
    navigator.clipboard.writeText(credentialStandard);
    setCredsCopied(true);
    hideTooltipAfterTimeout(setShowClickedStandard);
  };

  const copyAdminToClipboard = () => {
    navigator.clipboard.writeText(credentialAdmin);
    setCredsCopied(true);
    hideTooltipAfterTimeout(setShowClickedAdmin);
  };

  useEffect(() => {
    if (status === true) {
      setCredsCopied(false);
      getCredentials();
    }
  }, [status]);

  return (
    <Modal show={status} onHide={() => setShow(false)} centered className="k-z-ind-9999">
      <Modal.Header className="p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg">
        <Row className="mt-1 k-panel-cent-vert">
          <Col className="col-10">
            <Modal.Title as="h5" className="p-1">
              {title}
            </Modal.Title>
          </Col>
          <Col className="col-2 k-center-content-hor-right">
            <button
              className="custom-close-button k-pointer"
              onClick={() => setShow(false)}
              style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
            >
              <img className="k-mh-20 k-opac-50pc k-pointer k-mb-02r" src={closeBtn} alt="close" />
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className="txt-12 pt-2">To reveal the password, click on the dots that conceal the password. To copy the password, click on the copy button.</span>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
            <>
              {!credentialStatus ? (
                <Spinner animation="border" variant="secondary" />
              ) : (
                <>
                  <Row onMouseEnter={() => setIsAdminHovered(true)} onMouseLeave={() => setIsAdminHovered(false)}>
                    <Col className="col-10">
                      <Row>
                        <Col className='k-mb-m-20'>
                          <span className="k-clip-text txt-10 pt-2">
                            Admin Password
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="k-clip-text txt-14 pt-2 color-RS" onClick={toggleAdminPasswordVisibility} style={{ cursor: 'pointer' }}>
                            {isAdminPasswordVisible ? credentialAdmin : '•'.repeat(credentialAdmin.length)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-2">

                      {isAdminHovered && (
                        <button
                          className="k-click mt-3"
                          ref={targetAdmin}
                          onClick={copyAdminToClipboard}
                          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
                        >
                          <BiCopy />
                        </button>
                      )}
                      <Overlay target={targetAdmin.current} show={showAdmin} placement="right">
                        {(props) => (
                          <Tooltip className="k-z-ind-99999 k-clicked-tooltip" id="overlay-example" {...props}>
                            Copied!
                          </Tooltip>
                        )}
                      </Overlay>
                    </Col>
                  </Row>
                  <Row onMouseEnter={() => setIsStandardHovered(true)} onMouseLeave={() => setIsStandardHovered(false)}>
                    <Col className="col-10">
                      <Row>
                        <Col className='k-mb-m-20'>
                          <span className="k-clip-text txt-10 pt-2">
                            User Password
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="k-clip-text txt-14 pt-2 color-RS" onClick={toggleStandardPasswordVisibility} style={{ cursor: 'pointer' }}>
                            {isStandardPasswordVisible ? credentialStandard : '•'.repeat(credentialStandard.length)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-2">
                      {isStandardHovered && (
                        <button
                          ref={targetStandard}
                          className="k-click mt-3"
                          onClick={copyStandardToClipboard}
                          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
                        >
                          <BiCopy />
                        </button>
                      )}
                      <Overlay target={targetStandard.current} show={showStandard} placement="right">
                        {(props) => (
                          <Tooltip className="k-z-ind-99999 k-clicked-tooltip" id="overlay-example" {...props}>
                            Copied!
                          </Tooltip>
                        )}
                      </Overlay>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export const CenterModalSetScreenSize: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodStatus }) => {
  const [sizeStatus, setSizeStatus] = useState<boolean>(false);
  const [screenSizes, setScreenSizes] = useState<{ label: string; value: string }[]>([]);
  const [selectedSize, setSelectedSize] = useState<{ label: string; value: string } | null>(null); // State for single selected size
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSetScreenSize, setHasSetScreenSize] = useState<boolean>(false);

  const [screenStatus, setScreenStatus] = useState<boolean>(false);
  const [screenSizeResponse, setScreenSizeResponse] = useState<string>('');
  const [isLoadingSet, setLoadingSet] = useState(false);

  const [isScreenSizesFetched, setIsScreenSizesFetched] = useState<boolean>(false); // New state to track when screen sizes are fetched

  const getScreenSizes = () => {
    setSizeStatus(false);

    const query = {
      id: prodId,
      body: {
        metatype: 'screensize',
        metasubtype: 'desktop',
      },
    };

    api.getMetadata(query)
      .then((result) => {
        setMessage('Screen sizes retrieved');
        const options = result.body.responseObj.Value.map((size: string) => ({
          label: size,
          value: size,
        })).sort((a:any, b:any) => a.label.localeCompare(b.label));
        setScreenSizes(options);
        setSizeStatus(true);
        setIsScreenSizesFetched(true); // Set this to true when screen sizes are fetched
      })
      .catch((error) => {
        setMessage('Oh ... something went wrong, screen sizes not retrieved!');
        setSizeStatus(true);
        setIsScreenSizesFetched(false); // Ensure this is false if there's an error
      });
  };

  const getProductMeta = () => {
    
    const query = {
      id: prodId,
      body: {
        key: 'screendimension',
      },
    };

    api.getProductMeta(query)
      .then((result) => {
        setMessage('Screen size retrieved');
        

        screenSizes.forEach((size) => {
          
          if (size.value === result.body.responseObj) {
            helpers.logToOutput('getProductMeta MATCH:', size.value);
          }
        });

        // Find the correct option in screenSizes that matches the retrieved screen size
        const matchedSize = screenSizes.find(
          (size) => size.value === result.body.responseObj
        );

        // Set the selected size state with the matched size
        setSelectedSize(matchedSize || null);
      })
      .catch((error) => {
        setMessage('Oh ... something went wrong, screen sizes not retrieved!');
      });
  };

  const setScreenSize = async (newScreenSize: string, productID: string) => {
    setScreenStatus(false);
    
    const putResponse = await api.putProductMeta({
      id: productID,
      body: {
        key: 'screendimension',
        value: newScreenSize,
      },
    });

    

    if (putResponse.status === 200) {
      setScreenStatus(true);
      setScreenSizeResponse('All good... your desktop screen size has been set');
      setLoadingSet(false);
      setHasSetScreenSize(true);
      setIsLoading(false)
      setShow(false)
      //update product state by asking the websocket to grab the product list
      //callback()
    } else {
      setScreenStatus(true);
      //callback()
      setScreenSizeResponse('Oh ... something went wrong!');
      setLoadingSet(false);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    setHasSetScreenSize(false)
  }, );

  useEffect(() => {
    if (status === true) {
      getScreenSizes();
    }
  }, [status]);

  useEffect(() => {
    if (isScreenSizesFetched) {
      getProductMeta(); // Run this when screen sizes have been fetched
    }
  }, [isScreenSizesFetched]);

  const handleSizeChange = (selected: { label: string; value: string }[]) => {
    if (selected.length > 1) {
      // Prevent more than one selection
      setSelectedSize(selected[selected.length - 1]);
    } else {
      setSelectedSize(selected[0]);
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    // Simulate an API call
    setScreenSize(selectedSize?.value || '', prodId);
  };

  const valueRenderer = (selected: { label: string; value: string }[]) => {
    if (selected.length === 0) {
      return 'Select a screen size';
    }
    return selected[0]?.label; // Show only the selected item
  };

  return (
    <Modal show={status} onHide={() => setShow(false)} centered className="k-z-ind-9999">
      <Modal.Header className="p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg">
        <Row className="mt-1 k-panel-cent-vert">
          <Col className="col-10">
            <Modal.Title as="h5" className="p-1">
              {title}
            </Modal.Title>
          </Col>
          <Col className="col-2 k-center-content-hor-right">
            <button
              className="custom-close-button k-pointer"
              onClick={() => setShow(false)}
              style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
            >
              <img className="k-mh-20 k-opac-50pc k-pointer k-mb-02r" src={closeBtn} alt="close" />
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className="txt-12 pt-2">Select a screen size from one of the options below, when set the selected size will be used for all future sessions.</span>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
            <>
              {!sizeStatus ? (
                <Spinner animation="border" variant="secondary" />
              ) : (
                <>
                  <Row>
                    <Col className='col-9'>
                      <MultiSelect
                        options={screenSizes}
                        value={selectedSize ? [selectedSize] : []}
                        onChange={handleSizeChange}
                        valueRenderer={valueRenderer}
                        hasSelectAll={false}
                        labelledBy="Select"
                        disableSearch={true} // Disable search
                      />
                    </Col>
                    <Col className='col-3 d-flex justify-content-center align-items-center'>
                      <Button
                        className="k-min-w-103 p-2"
                        variant="outline-dark"
                        disabled={isLoading}
                        onClick={!isLoading ? handleClick : undefined}
                      >
                        {isLoading ? 'updating...' : 'update'}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export const CenterModalShareUser: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodShare, callback }) => {

  const [getShareUserStatus, setGetShareUserStatus] = useState<boolean>(false);
  const [setShareUserStatus, setSetShareUserStatus] = useState<boolean>(false);
  const [shareUserResponse, setShareUserResponse] = useState<string>('');  

  const [selected, setSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [oldSelected, setOldSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [isLoading, setLoading] = useState(false);
  const [hasShared, setHasShared] = useState(false);
  const [userList, setUserList] = useState<types.IUserListAttributes[]>([]); 
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  let usersToAdd:any = null;
  let usersToRemove:any = null;

  const getUsers = async () => {

    setGetShareUserStatus(false);
    const getUsersResponse = await api.getUsers({});  

    if ( getUsersResponse.status === variables.DEFAULT_API_RESULT.SUCCESS){
      setUserList(getUsersResponse.body);
    } else {
      /**
         * TO-DO:
         * - DO some error pop-up here for eg.
         */
      helpers.logToOutput('User Profile Attributes: Err: ', getUsersResponse.errorMessage);
    }
  }

  const mapUserIdsToUsers = (userIds: string[]) => {
    return userIds && userIds.map(userId => {
      const user = userList.find(user => user.id === userId);
      if (user) {
        return {
          label: user.display_name,
          value: userId
        };
      }
      return null;
    }).filter(Boolean); // Remove any null values (in case a user is not found)
  };

  const putShareProductUser = async (userID: string, productID: string) => {
    setSetShareUserStatus(false);
    
    const setProductShareResponse = await api.putShareProductUser({
      id: productID,
      body: {
        id: userID
      }
    });


    
    if ( setProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareUserStatus(true);
      setShareUserResponse('All good... your desktop had been shared');
      setLoading(false);
      setHasShared(true);
      //update product state by asking the websocket to grab the product list
      callback()
      toast.success('User has been added!')
    } else {
      setSetShareUserStatus(true);
      setLoading(false);
      callback()

      setShareUserResponse('Oh ... something went wrong!');
      
      toast.warn('Sorry the user wasn\'t added, are you the owner?')
    }
  }

  const delShareProductUser = async (userID: string, productID: string) => {
    setSetShareUserStatus(false);
    
    const delProductShareResponse = await api.delShareProductUser({
      id: productID,
      body: {
        id: userID
      }
    });

    
    if ( delProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareUserStatus(true);
      setShareUserResponse('All good... user has been removed from desktop sharing');
      setLoading(false);
      setHasShared(true);
      callback()
      toast.success('User has been removed!')
    } else {
      setSetShareUserStatus(true);
      /**
       * TO-DO:
       * - DO some error pop-up here for eg.
       */
      callback()
      setShareUserResponse('Oh ... something went wrong!');
      setLoading(false);
      toast.warn('Sorry the user wasn\'t removed, are you the owner?')
    }
  }

  const valueRenderer = (selected: typeof options) => {

    if (!selected.length) {
      return <></>;
    }

    return selected.length === 1 ? (
      <span className='k-bg-selected m-1 p-1 b-rad-5'>{selected[0].label}</span>
    ) : (
      selected.map(({ label }) => <span className='k-bg-selected m-1 p-1 b-rad-5' key={label}>{label}</span>)
    );

    
  };

  const handleClick = () => setLoading(true);

  const updateSharedUsers = async () => {
    usersToAdd = null;
    usersToRemove = null;

    if(oldSelected.length > 0) {
      // Find users to add (in newSharedUsers but not in originalSharedUsers)
      usersToAdd = selected.filter(user => !oldSelected.includes(user));

      // Find users to remove (in originalSharedUsers but not in newSharedUsers)
      //usersToRemove = oldSelected.filter(user => !selected.includes(user));
      usersToRemove = oldSelected.filter(user => !selected.some(selectedUser => selectedUser.value === user.value));
    } else {
      usersToAdd = selected
    }

    // Add users
    if(usersToAdd) {
      for (const user of usersToAdd) {
        await putShareProductUser(user.value, prodId);
      }
    }

    // Remove users
    if(usersToRemove) {
      for (const user of usersToRemove) {
        await delShareProductUser(user.value, prodId);
      }
    }

    // Update the originalSharedUsers with the newSharedUsers
    setOldSelected(selected);
    handleClose();
  };


  //Run when the status of the modal changes .. i.e. opens
  useEffect(() => {
    if (status == true){
      setHasShared(false);
      // Call getUsers and wait for its completion using await
      const fetchData = async () => {
        await getUsers();

        
        // After getUsers has finished, update the selected state
        if(prodShare) {
          setSelected(mapUserIdsToUsers(prodShare));
          setOldSelected(mapUserIdsToUsers(prodShare))
        } else {
          setSelected([])
        }
      };

      fetchData();
    }
  }, [status]);

  useEffect(() => {

    if (isLoading) {
      //simulateNetworkRequest().then(() => {
      //  setLoading(false);
      //  setHasShared(true);
      //});
      //putShareProductUser('8f6bca89-8502-4197-ac0f-139858f96ddc', prodId);
      updateSharedUsers();
    }
  }, [isLoading]);

  useEffect(() => {
    // Transform the user data into options for the dropdown
    const userOptions = userList && userList.map((user) => ({
      label: user.display_name,
      value: user.id,
    }));

    setOptions(userOptions);

    // Map shared user id to display name
    if(prodShare) {
      setSelected(mapUserIdsToUsers(prodShare));
      setOldSelected(mapUserIdsToUsers(prodShare))
    } else {
      setSelected([])
    }
  }, [userList]);

  const handleClose = () => {
    setShow(false);
    setHasShared(false);
    //setSelected([]);
    //setOldSelected([]);
  };


  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999' dialogClassName="k-min-w-600">
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-mh-35 k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Select the users from the dropdown box, and share your desktop!</span>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
              
            <Row>
              <Col className='col-9'>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  valueRenderer={valueRenderer}
                  hasSelectAll={false}
                  labelledBy="Select"
                />
              </Col>
              <Col className='col-3 d-flex justify-content-center align-items-center'>
                <Button className="k-min-w-103 p-2" variant="outline-dark" disabled={isLoading} onClick={!isLoading ? handleClick : undefined}>
                  {isLoading ? 'updating...' : 'update'}
                </Button>
              </Col>
            </Row>
            <Row>
              <span className='txt-12 pt-2'>{hasShared ? 'Updated!' : ' '}</span>
            </Row>

            {/*<Spinner animation="border" variant="secondary" />*/}
            
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalChangeUsername: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodShare, callback }) => {

  const [getShareUserStatus, setGetShareUserStatus] = useState<boolean>(false);
  const [setShareUserStatus, setSetShareUserStatus] = useState<boolean>(false);
  const [shareUserResponse, setShareUserResponse] = useState<string>('');

  const [selected, setSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [oldSelected, setOldSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [isLoading, setLoading] = useState(false);
  const [hasShared, setHasShared] = useState(false);
  const [userList, setUserList] = useState<types.IUserListAttributes[]>([]); 
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  let usersToAdd:any = null;
  let usersToRemove:any = null;

  const getUsers = async () => {

    setGetShareUserStatus(false);
    const getUsersResponse = await api.getUsers({});  

    if ( getUsersResponse.status === variables.DEFAULT_API_RESULT.SUCCESS){
      setUserList(getUsersResponse.body);
    } else {
      /**
         * TO-DO:
         * - DO some error pop-up here for eg.
         */
      helpers.logToOutput('User Profile Attributes: Err: ', getUsersResponse.errorMessage);
    }
  }

  const mapUserIdsToUsers = (userIds: string[]) => {
    return userIds && userIds.map(userId => {
      const user = userList.find(user => user.id === userId);
      if (user) {
        return {
          label: user.display_name,
          value: userId
        };
      }
      return null;
    }).filter(Boolean); // Remove any null values (in case a user is not found)
  };

  const putShareProductUser = async (userID: string, productID: string) => {
    setSetShareUserStatus(false);
    
    const setProductShareResponse = await api.putShareProductUser({
      id: productID,
      body: {
        id: userID
      }
    });


    
    if ( setProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareUserStatus(true);
      setShareUserResponse('All good... your desktop had been shared');
      setLoading(false);
      setHasShared(true);
      //update product state by asking the websocket to grab the product list
      callback()
      toast.success('User has been added!')
    } else {
      setSetShareUserStatus(true);
      setLoading(false);
      callback()

      setShareUserResponse('Oh ... something went wrong!');
      
      toast.warn('Sorry the user wasn\'t added, are you the owner?')
    }
  }

  const delShareProductUser = async (userID: string, productID: string) => {
    setSetShareUserStatus(false);
    
    const delProductShareResponse = await api.delShareProductUser({
      id: productID,
      body: {
        id: userID
      }
    });

    
    if ( delProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareUserStatus(true);
      setShareUserResponse('All good... user has been removed from desktop sharing');
      setLoading(false);
      setHasShared(true);
      callback()
      toast.success('User has been removed!')
    } else {
      setSetShareUserStatus(true);
      /**
       * TO-DO:
       * - DO some error pop-up here for eg.
       */
      callback()
      setShareUserResponse('Oh ... something went wrong!');
      setLoading(false);
      toast.warn('Sorry the user wasn\'t removed, are you the owner?')
    }
  }

  const valueRenderer = (selected: typeof options) => {

    if (!selected.length) {
      return <></>;
    }

    return selected.length === 1 ? (
      <span className='k-bg-selected m-1 p-1 b-rad-5'>{selected[0].label}</span>
    ) : (
      selected.map(({ label }) => <span className='k-bg-selected m-1 p-1 b-rad-5' key={label}>{label}</span>)
    );

    
  };

  const handleClick = () => setLoading(true);

  const updateSharedUsers = async () => {
    usersToAdd = null;
    usersToRemove = null;

    if(oldSelected.length > 0) {
      // Find users to add (in newSharedUsers but not in originalSharedUsers)
      usersToAdd = selected.filter(user => !oldSelected.includes(user));

      // Find users to remove (in originalSharedUsers but not in newSharedUsers)
      //usersToRemove = oldSelected.filter(user => !selected.includes(user));
      usersToRemove = oldSelected.filter(user => !selected.some(selectedUser => selectedUser.value === user.value));
    } else {
      usersToAdd = selected
    }

    // Add users
    if(usersToAdd) {
      for (const user of usersToAdd) {
        await putShareProductUser(user.value, prodId);
      }
    }

    // Remove users
    if(usersToRemove) {
      for (const user of usersToRemove) {
        await delShareProductUser(user.value, prodId);
      }
    }

    // Update the originalSharedUsers with the newSharedUsers
    setOldSelected(selected);
    handleClose();
  };


  //Run when the status of the modal changes .. i.e. opens
  useEffect(() => {
    if (status == true){
      setHasShared(false);
      // Call getUsers and wait for its completion using await
      const fetchData = async () => {
        await getUsers();

        
        // After getUsers has finished, update the selected state
        if(prodShare) {
          setSelected(mapUserIdsToUsers(prodShare));
          setOldSelected(mapUserIdsToUsers(prodShare))
        } else {
          setSelected([])
        }
      };

      fetchData();
    }
  }, [status]);

  useEffect(() => {

    if (isLoading) {
      //simulateNetworkRequest().then(() => {
      //  setLoading(false);
      //  setHasShared(true);
      //});
      //putShareProductUser('8f6bca89-8502-4197-ac0f-139858f96ddc', prodId);
      updateSharedUsers();
    }
  }, [isLoading]);

  useEffect(() => {
    // Transform the user data into options for the dropdown
    const userOptions = userList && userList.map((user) => ({
      label: user.display_name,
      value: user.id,
    }));

    setOptions(userOptions);

    // Map shared user id to display name
    if(prodShare) {
      setSelected(mapUserIdsToUsers(prodShare));
      setOldSelected(mapUserIdsToUsers(prodShare))
    } else {
      setSelected([])
    }
  }, [userList]);

  const handleClose = () => {
    setShow(false);
    setHasShared(false);
    //setSelected([]);
    //setOldSelected([]);
  };


  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999' dialogClassName="k-min-w-600">
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-mh-35 k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Select the users from the dropdown box, and share your desktop!</span>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
              
            <Row>
              <Col className='col-9'>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  valueRenderer={valueRenderer}
                  hasSelectAll={false}
                  labelledBy="Select"
                />
              </Col>
              <Col className='col-3 d-flex justify-content-center align-items-center'>
                <Button className="k-min-w-103 p-2" variant="outline-dark" disabled={isLoading} onClick={!isLoading ? handleClick : undefined}>
                  {isLoading ? 'updating...' : 'update'}
                </Button>
              </Col>
            </Row>
            <Row>
              <span className='txt-12 pt-2'>{hasShared ? 'Updated!' : ' '}</span>
            </Row>

            {/*<Spinner animation="border" variant="secondary" />*/}
            
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalSecurePaste: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodShare, callback }) => {
  const [isLoading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const [url, setUrl] = useState(''); // State for URL input

  const handleClick = () => {
    if (url && helpers.isValidUrl(url)) {
      setLoading(true);
      putSendText(url, prodId);
    } else {
      toast.warn('Please enter a valid URL');
    }
  };

  const putSendText = async (text: string, productID: string) => {
    setApiStatus(false);
    const encodedText = btoa(text);
    const apiResponse = await api.putProductPaste({
      id: productID,
      body: {
        key: 'windowschrome',
        action: 'paste',
        value: encodedText
      }
    });
    if (apiResponse?.parameter_value.result === variables.DEFAULT_API_RESULT.SUCCESS) {
      setApiStatus(true);
      setLoading(false);
      setHasSent(true);
      setUrl(''); // Clear the input field
      setShow(false); // Close the modal
      toast.success('Your URL has been sent! Please wait a few moments and it will open in chrome on your desktop');
    } else {
      setApiStatus(true);
      setLoading(false);
      toast.warn(`Sorry that didn't work: ${apiResponse?.parameter_value.result}`);
    }
  };

  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999' dialogClassName="k-min-w-600">
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-mh-35 k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn} alt="close" />
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className='pt-1'>
          <Col className='col-8'><span className='txt-12 pt-2'>Enter the URL you wish to send to the desktop</span></Col>
          <Col className='col-4 k-center-content-hor-right'>
            <OverlayTrigger
              trigger="click"
              key='top'
              placement='top'
              rootClose
              overlay={
                <Popover className="k-z-ind-999996" id="url-popover-positioned-top" style={{ maxWidth: '600px', fontSize: '0.65rem' }}>
                  <Popover.Header as="h3" className='ps-2'>URL Help</Popover.Header>
                  <Popover.Body className='ps-2 pt-2 pb-2 pe-2 k-w-500 '>
                    <Row className='p-0'>
                      <Col className='col-6'>
                        <strong className='mb-0 pb-2'>Accepted Formats - <span className='k-prod-start'> CORRECT</span></strong>
                        <ul className='ps-3 mb-0'>
                          <li>https://example.com</li>
                          <li>http://example.com/path?query=value</li>
                          <li>ftp://example.com</li>
                          <li>customprotocol://example.com/path</li>
                          <li>example.com</li>
                          <li>example.com/path/to/resource</li>
                          <li>/path/to/resource</li>
                          <li>subfolder/resource.txt</li>
                        </ul>
                      </Col>
                      <Col className='col-6'>
                        <strong className='mb-0 pb-2'>Rejected Formats  - <span className='k-prod-stop'> INCORRECT</span></strong>
                        <ul className='ps-3 mb-0'>
                          <li>://example.com (missing scheme)</li>
                          <li>http:/example.com (incomplete scheme)</li>
                          <li>/path with space/resource</li>
                          <li>example.com/path with space</li>
                          <li>http:// (no domain)</li>
                          <li>ftp:// (no domain)</li>
                          <li>"" (empty string or space only)</li>
                          <li>user@domain (email URL structure)</li>
                        </ul>
                      </Col>
                    </Row>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link" className='p-0 txt-12 text-decoration-none'>What is a valid URL?</Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
            <Row>
              <Col className='col-9'>
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}  // Capture URL input
                  disabled={isLoading}
                />
              </Col>
              <Col className='col-3 d-flex justify-content-center align-items-center'>
                <Button className="k-min-w-103 p-2" variant="outline-dark" disabled={isLoading} onClick={!isLoading ? handleClick : undefined}>
                  {isLoading ? 'Sending...' : 'Send'}
                </Button>
              </Col>
            </Row>
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export const CenterModalShareGroup: React.FC<types.IModal> = ({ status, title, setShow, prodId, prodShare, callback }) => {

  const [getShareGroupStatus, setGetShareGroupStatus] = useState<boolean>(false);
  const [setShareGroupStatus, setSetShareGroupStatus] = useState<boolean>(false);
  const [shareGroupResponse, setShareGroupResponse] = useState<string>('');  

  const [selected, setSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [oldSelected, setOldSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [isLoading, setLoading] = useState(false);
  const [hasShared, setHasShared] = useState(false);
  const [groupList, setGroupList] = useState<types.IGroupListAttributes[]>([]); 
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  const authContext = useContext(authContextHelper.AuthContext);
  let groupsToAdd:any = null;
  let groupsToRemove:any = null;

  const getGroups = async () => {

    setGetShareGroupStatus(false);
    const getGroupsResponse = await api.getGroups({});
    
    
    
    

    if ( getGroupsResponse.status === variables.DEFAULT_API_RESULT.SUCCESS){
      setGroupList(getGroupsResponse.body.groups);
      
    } else {
      /**
         * TO-DO:
         * - DO some error pop-up here for eg.
         */
      helpers.logToOutput('Group Profile Attributes: Err: ', getGroupsResponse.errorMessage);
    }
  }

  const mapGroupIdsToGroups = (groupIds: string[]) => {
    return groupIds && groupIds.map(groupId => {
      const group = groupList.find(group => group.id === groupId);
      if (group) {
        return {
          label: group.name,
          value: groupId
        };
      }
      return null;
    }).filter(Boolean); // Remove any null values (in case a group is not found)
  };

  const putShareProductGroup = async (groupID: string, productID: string) => {
    setSetShareGroupStatus(false);
    
    const setProductShareResponse = await api.putShareProductGroup({
      id: productID,
      body: {
        id: groupID
      }
    });

    
    if ( setProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareGroupStatus(true);
      setShareGroupResponse('All good... your desktop had been shared');
      setLoading(false);
      setHasShared(true);
      //update product state by asking the websocket to grab the product list
      callback()
      toast.success('Successfully Added Group')
    } else {
      setSetShareGroupStatus(true);
      callback()
      /**
       * TO-DO:
       * - DO some error pop-up here for eg.
       */
      setShareGroupResponse('Oh ... something went wrong!');
      toast.warn('Sorry the group wasn\'t added, are you the owner?')
      setLoading(false);
    }
  }

  const delShareProductGroup = async (groupID: string, productID: string) => {
    setSetShareGroupStatus(false);
    
    const delProductShareResponse = await api.delShareProductGroup({
      id: productID,
      body: {
        id: groupID
      }
    });

    
    if ( delProductShareResponse.result === variables.DEFAULT_API_RESULT.SUCCESS){
      setSetShareGroupStatus(true);
      setShareGroupResponse('All good... Group has been removed from desktop sharing');
      setLoading(false);
      setHasShared(true);
      callback()
      toast.success('Successfully Removed Group')
    } else {
      setSetShareGroupStatus(true);
      /**
       * TO-DO:
       * - DO some error pop-up here for eg.
       */
      callback()
      setShareGroupResponse('Oh ... something went wrong!');
      setLoading(false);
      toast.warn('Sorry the group wasn\'t removed, are you the owner?')
    }
  }

  const valueRenderer = (selected: typeof options) => {

    if (!selected.length) {
      return <></>;
    }

    return selected.length === 1 ? (
      <span className='k-bg-selected m-1 p-1 b-rad-5'>{selected[0].label}</span>
    ) : (
      selected.map(({ label }) => <span className='k-bg-selected m-1 p-1 b-rad-5' key={label}>{label}</span>)
    );

    
  };

  const handleClick = () => setLoading(true);

  const updateSharedGroups = async () => {
    groupsToAdd = null;
    groupsToRemove = null;

    if(oldSelected.length > 0) {
      // Find groups to add (in newSharedGroup but not in originalSharedGroup)
      groupsToAdd = selected.filter(group => !oldSelected.includes(group));

      // Find groups to remove (in originalSharedGroup but not in newSharedGroup)
      groupsToRemove = oldSelected.filter(group => !selected.some(selectedGroup => selectedGroup.value === group.value));
    } else {
      groupsToAdd = selected
    }

    // Add groups
    if(groupsToAdd) {
      for (const group of groupsToAdd) {
        await putShareProductGroup(group.value, prodId);
      }
    }

    // Remove groups
    if(groupsToRemove) {
      for (const group of groupsToRemove) {
        await delShareProductGroup(group.value, prodId);
      }
    }

    // Update the originalSharedGroups with the newSharedGroups
    setOldSelected(selected);
    handleClose();
  };


  //Run when the status of the modal changes .. i.e. opens
  useEffect(() => {
    if (status == true){
      setHasShared(false);
      // Call getGroups and wait for its completion using await
      const fetchData = async () => {
        await getGroups();

        
        // After getGroups has finished, update the selected state
        if(prodShare) {
          setSelected(mapGroupIdsToGroups(prodShare));
          setOldSelected(mapGroupIdsToGroups(prodShare))
        } else {
          setSelected([])
        }
      };

      fetchData();
    }
  }, [status]);

  useEffect(() => {

    if (isLoading) {
      updateSharedGroups();
    }
  }, [isLoading]);

  useEffect(() => {
    // Transform the group data into options for the dropdown
    const groupOptions = groupList && groupList.map((group) => ({
      label: group.name,
      value: group.id,
    }));

    setOptions(groupOptions);

    // Map shared group id to display name
    if(prodShare) {
      setSelected(mapGroupIdsToGroups(prodShare));
      setOldSelected(mapGroupIdsToGroups(prodShare))
    } else {
      setSelected([])
    }
  }, [groupList]);

  const handleClose = () => {
    setShow(false);
    setHasShared(false);
    //setSelected([]);
    //setOldSelected([]);
  };


  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999' dialogClassName="k-min-w-600">
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-mh-35 k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Select the groups from the dropdown box, and share your desktop!</span>
        </Row>
        <Row>
          <p className="mb-0 mt-2">
              
            <Row>
              <Col className='col-9'>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  valueRenderer={valueRenderer}
                  hasSelectAll={false}
                  labelledBy="Select"
                />
              </Col>
              <Col className='col-3 d-flex justify-content-center align-items-center'>
                <Button className="k-min-w-103 p-2" variant="outline-dark" disabled={isLoading} onClick={!isLoading ? handleClick : undefined}>
                  {isLoading ? 'updating...' : 'update'}
                </Button>
              </Col>
            </Row>
            <Row>
              <span className='txt-12 pt-2'>{hasShared ? 'Updated!' : ' '}</span>
            </Row>

            {/*<Spinner animation="border" variant="secondary" />*/}
            
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalProdAddResult: React.FC<types.IModal> = ({ status, title, setShow }) => {
  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className='col-12'>
            <Row className='pt-2'><Col className='col-12'><span className='txt-14 pt-2'>Success! Your virtual machine is starting, it will appear on the desktop shortly. You can now close this window</span></Col></Row>
            {/*<Row className='pt-2'><Col className='col-12'><span className='txt-14 pt-2'>You can now close this window</span></Col></Row>*/}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const CenterModalProdTerminate: React.FC<types.IModal> = ({ status, title, setShow, prodId, callback, prodName }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const terminateProduct = async(productID: any) => {
    setIsLoading(true);
    callback(productID, 'terminate', 'Terminate', 'Product has been removed', 0); // Notify the parent

    // Simulate an async operation unitil we put proper feedback in from API
    setTimeout(() => {
      setShow(false)
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed for your use case
  };


  return (
    <Modal show={status} onHide={() => setShow(false)} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={() => setShow(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className='col-12'>
            <Row className='pt-2'>
              <Col className='col-8'>
                <span className='txt-14 pt-2'>Please confirm that you wish to remove <span className='txt-bold'>{prodName || 'this device'}</span>. Once confirmed cannot be undone</span>
              </Col>
              <Col className='col-4 k-center-content'>
                {isLoading ? (
                  <div className='k-mh-25 k-center-content-hor-right k-center-content'>
                    <Spinner animation="border" role="status" >
                      <span className="visually-hidden">Removing...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div  className='k-center-content-hor-right k-center-content'>
                    <Button className='k-mh-45' variant="light" onClick={() => {terminateProduct(prodId)}}>Confirm</Button>
                  </div>
                )}
              </Col>
            </Row>
            {/*<Row className='pt-2'><Col className='col-12'><span className='txt-14 pt-2'>You can now close this window</span></Col></Row>*/}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const ProductTerminate: React.FC<types.IGenericPromptModal> = ({ isVisible, content, handleModalHide, handleModalPromptConfirm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTerminateConfirm = async () => {
    setIsLoading(true);
    // callback(productID, 'terminate', 'Terminate', 'Product has been removed', 0); // Notify the parent// confirm
    await handleModalPromptConfirm();

    // Simulate an async operation unitil we put proper feedback in from API
    setTimeout(() => {
      setIsLoading(false); // Disable loading
      handleModalHide(); // Hide modal 
    }, 1000); // Adjust the delay as needed for your use case
  };


  return (
    <Modal show={isVisible} onHide={handleModalHide} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>Confirm Termination</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={handleModalHide} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className='col-12'>
            <Row className='pt-2'>
              <Col className='col-8'>
                <span className='txt-14 pt-2'>Please confirm that you wish to remove <span className='txt-bold'>{content.product.name || 'this device'}</span>. Once confirmed cannot be undone</span>
              </Col>
              <Col className='col-4 k-center-content'>
                {isLoading ? (
                  <div className='k-mh-25 k-center-content-hor-right k-center-content'>
                    <Spinner animation="border" role="status" >
                      <span className="visually-hidden">{content.loadingMessage || 'Removing...'}</span>
                    </Spinner>
                  </div>
                ) : (
                  handleTerminateConfirm && <div  className='k-center-content-hor-right k-center-content'>
                    <Button className='k-mh-45' variant="light" onClick={handleTerminateConfirm}>Confirm</Button>
                  </div>
                )}
              </Col>
            </Row>
            {/*<Row className='pt-2'><Col className='col-12'><span className='txt-14 pt-2'>You can now close this window</span></Col></Row>*/}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export const ProductPresignedLink: React.FC<types.IGenericPromptModal> = ({ isVisible, content, handleModalHide }) => {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content.product.shareUrl);
    setLinkCopied(true);    
  };
  
  //Run on load
  useEffect(() => {
    if (isVisible){
      setLinkCopied(false);
    }
  }, [isVisible]);

  

  return (
    <Modal show={isVisible} onHide={handleModalHide} centered className='k-z-ind-9999'>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>{content.title}</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={handleModalHide} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span className='txt-12 pt-2'>Generate your personalised link below to easily share with others. Simply copy and send it to allow them direct access to your virtual machine!</span>
        </Row>
        <Row>
          <p className="mb-0 mt-4">
            { content.product.isRunning ?
              <>
                {content.product.shareUrl ? 
                  <Row>
                    <Col className='col-10'>
                      <span className='k-clip-text txt-12 pt-2 color-RS'>{content.product.shareUrl}</span>
                    </Col>
                    <Col className='col-2'>
                      <button className='k-click' onClick={copyToClipboard} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                        <BiCopy />
                      </button>
                    </Col>
                  </Row>
                  :
                  <Spinner animation="border" variant="secondary" />
                }
              </>
              :
              <>
                <Row>
                  <Col className='col-12'>
                    <span className='k-clip-text txt-12 pt-1 color-RS'>Sorry! Your desktop needs to be running to generate a share link!</span>
                  </Col>
                </Row>
              </>
            }
            {
              /*linkCopied ? 
                  <Row><Col><span className='color-lightText'>copied!</span></Col></Row> 
                  : 
                  <Row><Col><span className='color-transparent'>_</span></Col></Row> 
                */
            }
          </p>
        </Row>
      </Modal.Body>
    </Modal>
  );
}


export const ConfirmReleaseModal: React.FC<types.ConfirmReleaseModalProps> = ({ show, number, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleConfirm = () => {
    onConfirm(inputValue);
    setInputValue(''); // Reset input value on confirm
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
        <Row className='mt-1 k-panel-cent-vert'>
          <Col className='col-10'>
            <Modal.Title as="h5" className='p-1'>Confirm Release</Modal.Title>
          </Col>
          <Col className='col-2 k-center-content-hor-right'>
            <button className='custom-close-button k-pointer' onClick={onClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className='pt-1'>
        <p>
          To confirm the release of <strong>{number}</strong> please type the number below. Once a number has been released it will be made available for the general public.
        </p>
        <Form>
          <Form.Group controlId="confirmationInput">
            <Form.Label>Enter Number</Form.Label>
            <Row>
              <Col className='col-9'>
                <Form.Control
                  type="text"
                  placeholder="Enter the number to confirm"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </Col>
              <Col className='col-3'>
                <Button className='k-mh-45' variant="light" onClick={handleConfirm}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};